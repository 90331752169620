import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactName: "",
      contactEmail: "",
      contactSubject: "",
      contactMessage: "",
      messageSuccess: false,
      messageError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  async handleSubmit(event) {
    event.preventDefault();

    const { contactName, contactEmail, contactSubject, contactMessage } =
      this.state;

    try {
      const response = await fetch("https://formspree.io/f/mvgpznjn", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: contactName,
          email: contactEmail,
          subject: contactSubject,
          message: contactMessage,
        }),
      });

      if (response.ok) {
        this.setState({ messageSuccess: true, messageError: false });
      } else {
        this.setState({ messageSuccess: false, messageError: true });
      }
    } catch (error) {
      console.error("Failed to send message:", error);
      this.setState({ messageSuccess: false, messageError: true });
    }
  }

  render() {
    if (!this.props.data) return null;

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="twelve columns">
              <form
                onSubmit={this.handleSubmit}
                id="contactForm"
                name="contactForm"
              >
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      value={this.state.contactName}
                      size="35"
                      id="contactName"
                      name="contactName"
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      value={this.state.contactEmail}
                      size="35"
                      id="contactEmail"
                      name="contactEmail"
                      onChange={this.handleChange}
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="contactSubject">Subject</label>
                    <input
                      type="text"
                      value={this.state.contactSubject}
                      size="35"
                      id="contactSubject"
                      name="contactSubject"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMessage">
                      Message <span className="required">*</span>
                    </label>
                    <textarea
                      cols="50"
                      rows="15"
                      id="contactMessage"
                      name="contactMessage"
                      value={this.state.contactMessage}
                      onChange={this.handleChange}
                      required
                    ></textarea>
                  </div>

                  <div>
                    <button type="submit" className="submit">
                      Submit
                    </button>
                    <span id="image-loader">
                      <img alt="" src="images/loader.gif" />
                    </span>
                  </div>
                </fieldset>
              </form>

              {this.state.messageError && <div id="message-warning">Error</div>}
              {this.state.messageSuccess && (
                <div id="message-success">
                  <i className="fa fa-check"></i>Your message was sent, thank
                  you!
                  <br />
                </div>
              )}
            </div>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
