import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faReact,
  faNodeJs,
  faPython,
  faJava,
  faAws,
  faGit,
  faDocker,
  faJsSquare,
  faFlutter,
  faNode,
  faJs,
  faAngular,
} from "@fortawesome/free-brands-svg-icons";
import { faServer, faCode, faCloud } from "@fortawesome/free-solid-svg-icons";
import { faDatabase } from "@fortawesome/free-solid-svg-icons/faDatabase";

// Add icons to the library
library.add(
  faReact,
  faNodeJs,
  faPython,
  faJava,
  faAws,
  faGit,
  faDocker,
  faJsSquare,
  faFlutter,
  faServer,
  faCode,
  faCloud,
  faDatabase
);

class Skills extends React.Component {
  getIcon(name) {
    switch (name.toLowerCase()) {
      case "react.js":
      case "react native":
      case "nextjs":
        return { icon: faReact, className: "react" };
      case "ionicjs":
        return { icon: faAngular, className: "angular" };
      case "node.js":
        return { icon: faNode, className: "nodejs" };
      case "express.js":
        return { icon: faJs, className: "js" };
      case "nestjs":
        return { icon: faNodeJs, className: "nodejs" };
      case "docker":
        return { icon: faDocker, className: "docker" };
      case "aws":
        return { icon: faAws, className: "aws" };
      case "git":
        return { icon: faGit, className: "git" };
      case "javascript":
        return { icon: faJsSquare, className: "javascript" };
      case "python":
        return { icon: faPython, className: "python" };
      case "java":
        return { icon: faJava, className: "java" };
      case "postgresql":
      case "mongodb":
        return { icon: faDatabase, className: "database" };
      case "tailwindcss":
        return { icon: faCode, className: "code" }; // Placeholder icon, change as needed
      case "flutter":
        return { icon: faFlutter, className: "flutter" }; // Placeholder icon, change as needed
      default:
        return { icon: faServer, className: "server" }; // Default icon
    }
  }

  render() {
    const { data } = this.props;

    return (
      <div className="skills">
        {data.skills.map((skill) => (
          <div key={skill.name} className="skill-category">
            <h3>{skill.name}</h3>
            <div className="skill-cards">
              {skill.details.map((detail) => {
                const { icon, className } = this.getIcon(detail.name);
                return (
                  <div key={detail.name} className="skill-card">
                    <FontAwesomeIcon
                      icon={icon}
                      size="3x"
                      className={`skill-icon ${className}`}
                    />
                    <span>{detail.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Skills;
