import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

let id = 0;
class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedImage: null,
    };
  }

  handleImageClick = (imageSrc) => {
    this.setState((prevState) => ({
      expandedImage: prevState.expandedImage === imageSrc ? null : imageSrc,
    }));
  };

  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map((project) => {
      let projectImage = "images/portfolio/" + project.image;
      const isExpanded = this.state.expandedImage === projectImage;

      return (
        <div key={id++} className="columns portfolio-item">
          <div className="item-wrap">
            <Zoom>
              <img
                alt={project.title}
                src={projectImage}
                className={isExpanded ? "expanded" : ""}
              />
            </Zoom>
            <div style={{ textAlign: "center" }}>
              <Fade bottom duration={1000}>
                <a href={project.url}>{project.title}</a>
              </Fade>
            </div>
          </div>
        </div>
      );
    });

    return (
      <section id="portfolio">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Check Out Some of My Works.</h1>

              <div
                id="portfolio-wrapper"
                className="bgrid-quarters s-bgrid-thirds cf"
              >
                {projects}
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
